import { StrictMode, useEffect, useState } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import TagManager from 'react-gtm-module';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import { loadCuraleafUIConfiguration } from 'ui';

import Layout from '../components/layout/layout';
import Loader from '../components/loader/loader';

import 'ui/dist/curaleaf.css';
import '../styles/global.scss';

import { datadogRum } from '@datadog/browser-rum';
import Cookies from 'js-cookie';

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.NEXT_PUBLIC_DD_SAMPLE_RATE
) {
  datadogRum.init({
    applicationId: '37f2d7ec-15a5-4911-ab6f-60a8d9c81363',
    clientToken: 'pub8b917f4ff4ec055ef938299d415a2416',
    site: 'datadoghq.com',
    service: 'curaleaf-main-website',
    env: process.env.DD_ENV || 'development',
    version: process.env.VERCEL_GIT_COMMIT_SHA || undefined,
    sampleRate: +process.env.NEXT_PUBLIC_DD_SAMPLE_RATE || 0,
    sessionReplaySampleRate:
      +process.env.NEXT_PUBLIC_DD_REPLAY_SAMPLE_RATE || 0,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackFrustrations: true
  });
  datadogRum.startSessionReplayRecording();
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.warn(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) {
    console.error('[Network error]: ', networkError);
  }
});

const retryLink = new RetryLink({
  attempts: {
    max: 3, // Maximum number of retry attempts.
    retryIf: (error) => {
      // This function determines if a request should be retried.
      // Return true to retry, false to give up.
      return !!error;
    }
  }
});

export const client = new ApolloClient({
  link: errorLink.concat(retryLink).concat(
    createHttpLink({
      uri: process.env.CURAQL_BASEURL,
      fetch,
      fetchOptions: {
        compress: true
      }
    })
  ),
  cache: new InMemoryCache()
});

export const preview_client = new ApolloClient({
  link: errorLink
    .concat(retryLink)
    .concat(createHttpLink({ uri: process.env.CURAQL_PREVIEW_BASEURL })),
  cache: new InMemoryCache()
});

loadCuraleafUIConfiguration({
  imageRenderer: Image
});

function MyApp({ Component, pageProps }: AppProps) {
  const { events, isFallback, isPreview, push, asPath } = useRouter();

  let is_android_ga = false;
  // Example: Read a query parameter named 'utm_source'
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('ga_platform');
    const gaUtmSource = Cookies.get('ga_platform');
    is_android_ga = utmSource === 'android' || gaUtmSource === 'android';
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ga_platform = queryParams.get('ga_platform');
    if (ga_platform) {
      Cookies.set('ga_platform', ga_platform, {
        expires: 1/24 // 1 hour
      });
      queryParams.delete('ga_platform');
      window.history.replaceState(
        {},
        document.title,
        `${location.pathname}?${queryParams}`
      );
    }
  }, [asPath.includes('?')]);

  const [initialRenderComplete, setInitialRenderComplete] =
    useState<boolean>(false);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  useEffect(() => {
    const purgeDataLayer = () => {
      if (typeof window !== 'undefined' && window?.dataLayer?.length) {
        window.dataLayer.length = 0;
      }
    };

    // Attach the event listener
    events.on('routeChangeStart', purgeDataLayer);

    return () => {
      // Detach the event listener when the component unmounts
      events.off('routeChangeStart', purgeDataLayer);
    };
  }, []);

  useEffect(() => {
    if (
      !process.env.NEXT_PUBLIC_HIDE_ANALYTICS &&
      process.env.NEXT_PUBLIC_GTM_ID
    ) {
      TagManager.initialize({
        gtmId:
          is_android_ga && process.env.NEXT_PUBLIC_GTM_ID_ANDROID
            ? process.env.NEXT_PUBLIC_GTM_ID_ANDROID
            : process.env.NEXT_PUBLIC_GTM_ID
      });
    }
  }, [events]);

  if (!initialRenderComplete) return <></>;

  if (isFallback) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  return (
    <ApolloProvider client={isPreview ? preview_client : client}>
      <Head>
        {/* Lets us see the latest commit in the HEAD of the website, validating the version of the code */}
        <meta
          name="release"
          content={process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {process.env.IOS_APP_ID ? (
          <meta
            name="apple-itunes-app"
            content={`app-id=${process.env.IOS_APP_ID}`}
          />
        ) : null}
        {process.env.NEXT_PUBLIC_GA4_ID ? (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${
                is_android_ga
                  ? process.env.NEXT_PUBLIC_GA4_ID_ANDROID
                  : process.env.NEXT_PUBLIC_GA4_ID
              }`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${
                    is_android_ga
                      ? process.env.NEXT_PUBLIC_GA4_ID_ANDROID
                      : process.env.NEXT_PUBLIC_GA4_ID
                  }', {
                    page_path: window.location.pathname,
                    send_page_view: false
                  });`
              }}
            />
          </>
        ) : null}
      </Head>
      <DefaultSeo
        titleTemplate="%s | Curaleaf"
        defaultTitle="Curaleaf - Cannabis with Confidence"
        dangerouslySetAllPagesToNoFollow={
          !!process.env.NEXT_PUBLIC_HIDE_ANALYTICS
        }
        dangerouslySetAllPagesToNoIndex={
          !!process.env.NEXT_PUBLIC_HIDE_ANALYTICS
        }
        openGraph={{
          type: 'website',
          locale: 'en_us',
          url: 'https://curaleaf.com',
          site_name: 'Curaleaf',
          images: [
            {
              url: 'https://images.contentstack.io/v3/assets/blt1244f6272732d4ea/blt6f775b721e0632a4/Curaleaf.jpeg',
              width: 1300,
              height: 987,
              alt: 'Curaleaf employee smiling inside a grow facility.'
            }
          ],
          description:
            'We are a leading vertically integrated medical and wellness cannabis operator in the United States. We craft high-quality cannabis products that are uncomplicated and approachable.'
        }}
        twitter={{
          handle: '@Curaleaf_Inc',
          site: 'curaleaf.com',
          cardType: 'summary'
        }}
      />
      <StrictMode>
        <Layout>
          {isPreview ? (
            <div className="PreviewButton">
              <button
                onClick={() => push('/api/disable-preview')}
                style={{
                  color: 'white',
                  width: '100%',
                  padding: 10,
                  backgroundColor: '#C5003E'
                }}>
                Disable Preview
              </button>
            </div>
          ) : null}
          <Component {...pageProps} />
        </Layout>
      </StrictMode>
    </ApolloProvider>
  );
}

export default MyApp;

import {
  CS_Website_Brandbar,
  CS_Website,
  CS_State,
  ConfigurationFormType,
  CS_DutchiePlusPreference,
  CS_StateAgeVerification
} from 'services';

import brandBarImport from './brand-bar.json';
const brandbar = brandBarImport as unknown as CS_Website_Brandbar;

import websiteData from './website.json';
const website = websiteData as unknown as CS_Website;

import statesImport from './states.json';
const states = statesImport as unknown as CS_State[];

import formConfigImport from './form-config.json';
const formConfig = formConfigImport as unknown as ConfigurationFormType;

import dutchiePlusPreferencesImport from './dutchie-plus-preferences.json';
const dutchiePlusPreferences =
  dutchiePlusPreferencesImport as unknown as CS_DutchiePlusPreference;

import ageVerificationImport from './age-verification.json';
const ageVerification =
  ageVerificationImport as unknown as CS_StateAgeVerification;

export {
  ageVerification,
  brandbar,
  formConfig,
  states,
  website,
  dutchiePlusPreferences
};

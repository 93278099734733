import { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';

import {
  CS_EmbeddableFormWrapper,
  CS_Website_OptInModal,
  SubmitFormToAPI,
  useStorage
} from 'services';
import {
  MarketingIntakeForm,
  embeddableFormMap,
  MarketingIntakeProps
} from 'ui';
import { FormsOptions } from 'ui/src/constants/types';

import { formConfig } from '../../../staticData/staticData';

import styles from './index.module.scss';

type Props = {
  form: CS_Website_OptInModal;
  open: boolean;
  toggle: () => void;
};

export default function MailingOptIn({ form, open, toggle }: Props) {
  const [promptedForMailing, setPromptedForMailing] = useStorage(
    'promptedForMailingList',
    '0'
  );

  const now = Date.now();

  const setMaybeLater = () => {
    if (promptedForMailing === '1') {
      toggle();
      return;
    }

    if (
      now < JSON.parse(promptedForMailing) ||
      now > JSON.parse(promptedForMailing) ||
      (typeof promptedForMailing === 'string' &&
        promptedForMailing.length > 2) ||
      !promptedForMailing // ?
    ) {
      const tenDaysLater = (() => {
        const date = new Date();
        date.setDate(date.getDate() + 10);
        return new Date(date).valueOf();
      })();
      setPromptedForMailing(JSON.stringify(tenDaysLater));
      toggle();
    }
  };

  let marketingIntakeForm: CS_EmbeddableFormWrapper['Embeddable_Form'];
  let options: FormsOptions & {
    type: 'Contact Us' | 'Opt In';
  };
  if (
    form &&
    form.embeddable_formConnection &&
    form.embeddable_formConnection.edges &&
    form.embeddable_formConnection.edges.length
  ) {
    marketingIntakeForm = {
      formConnection: { ...form.embeddable_formConnection }
    };

    options = {
      type: form.embeddable_formConnection.edges[0].node.type,
      config: formConfig,
      submitForm: (data) => {
        SubmitFormToAPI(data, 'Opt In');
        setPromptedForMailing('1');
      },
      confirmationCallback: () => toggle(),
      closeButton: {
        brand: process.env.BRAND,
        text: 'No Thanks',
        variant: 'TERTIARY',
        callBackFunc: () => setMaybeLater()
      }
    };
  } else {
    return null;
  }

  const updateForms = async () => {
    // NOTE: need this api in order to hide Braze api keys and to keep dispensaries up to date
    const getForm = await axios.get(
      `/api/form/${marketingIntakeForm.formConnection.edges[0].node.system.uid}`
    );
    if (getForm.status === 200) {
      marketingIntakeForm.formConnection.edges[0].node.statesConnection =
        getForm.data.forms.statesConnection;
      marketingIntakeForm.formConnection.edges[0].node.dispensariesConnection =
        getForm.data.forms.dispensariesConnection;
    }
  };

  useEffect(() => {
    updateForms();
  }, [form]);

  return (
    <Modal
      open={open}
      onClose={() => toggle()}
      aria-labelledby="contained-modal-title-vcenter"
      closeAfterTransition
      className={styles.modalContainer}>
      <div className={styles.formContainer}>
        <div className={styles.closeContainer}>
          <AiOutlineClose
            className={styles.close}
            onClick={() => {
              setMaybeLater();
            }}
          />
        </div>
        <MarketingIntakeForm
          {...(embeddableFormMap(
            { ...marketingIntakeForm },
            options,
            process.env.BRAND
          ) as MarketingIntakeProps)}
        />
      </div>
    </Modal>
  );
}

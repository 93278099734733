import Cookies from 'js-cookie';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import qs from 'qs';

import {
  Analytics,
  Curaql,
  useAnalytics,
  useStorage,
  useWindowDimensions
} from 'services';
import {
  AgeVerification,
  HeaderNavigation,
  FooterNavigation,
  LicenseBar,
  WideBanner,
  ageVerificationMap,
  footerMap,
  useDispensary,
  wideBannerMap,
  CartContextProvider,
  AuthContext,
  EcomContextProvider,
  DispensaryTitleBanner,
  layoutMap,
  HeaderNavigationProps,
  MailingOptInBanner
} from 'ui';
import {
  OrderType,
  PricingType,
  Product,
  ProductVariant
} from 'curaleaf-graph/curaql/generated/types';

import MailingOptIn from './mailingOptIn';

import { ageVerification, states, website } from '../../staticData/staticData';

import styles from './layout.module.scss';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

function Layout({ children }: Props) {
  const { asPath, isPreview } = useRouter();
  const analytics: Analytics = useAnalytics();
  const {
    csDispensary,
    orderType,
    pricingType,
    selectedDispensary,
    setOrderType,
    setPricingType,
    selectedStoreId,
    shopCategoryLinks,
    shopLink
  } = useDispensary();
  const { width } = useWindowDimensions();

  // LCOAL STORAGE
  const [ageConfirm, setAgeConfirm] = useStorage<'0' | '1'>(
    'confirmed21OrOlder',
    '0'
  );
  const [promptedForMailing] = useStorage('promptedForMailingList', '0');
  const [userTestGroup] = useStorage(
    'userTestGroup',
    Math.random() < 0.5 ? 'a' : 'b'
  );
  const [wideBanner, setWideBanner] = useStorage('wideBanner', '');

  // STATES
  const [ageVerificationOpen, toggleAgeVerification] = useState(false);
  const [layout, setLayout] = useState<{
    header: HeaderNavigationProps['navLinks'];
    socialLinks: HeaderNavigationProps['socialLinks'];
    userLinks: HeaderNavigationProps['userLinks'];
  }>({
    header: [],
    socialLinks: [],
    userLinks: []
  });
  const [navOpen, setNavOpen] = useState(false);
  const [optInModalOpen, setOptInModalOpen] = useState(false);
  const [popupProduct, setPopupProduct] = useState<{
    product: Product;
    variant: ProductVariant;
    quantity: number;
  } | null>(null);
  const [showPopup, togglePopup] = useState<boolean>(false);

  // USEEFFECT
  useEffect(() => {
    getLayout();
  }, [selectedDispensary, shopCategoryLinks]);

  useEffect(() => {
    const cartCookie = Cookies.get(Curaql.CART_META_KEY);
    if (cartCookie) {
      const parsedCartCookie = JSON.parse(cartCookie);
      const cartDispo = parsedCartCookie.dispensaryUniqueId;
      const cartMenuType = parsedCartCookie.menuType;
      if (cartDispo !== selectedStoreId || cartMenuType !== pricingType) {
        Cookies.remove(Curaql.CART_META_KEY);
      }
    }
  }, [pricingType]);

  useEffect(() => {
    const storageOrderType = window.localStorage.getItem(
      'orderType'
    ) as OrderType;
    if (storageOrderType && storageOrderType !== orderType) {
      setOrderType(JSON.parse(storageOrderType));
    }
  }, [orderType]);

  useEffect(() => {
    if (ageConfirm !== '1') {
      toggleAgeVerification(true);
    } else {
      const timer = website?.opt_in_modal?.delay
        ? website.opt_in_modal.delay
        : 1000;
      setTimeout(() => {
        const now = Date.now();
        const query = window?.location?.search?.length
          ? qs.parse(window.location.search.substring(1))
          : {};

        // Bypasses pop up if it is a UTM link (Paid Ad)
        if (
          query.utm_medium &&
          typeof query.utm_medium === 'string' &&
          query.utm_medium.toLowerCase() === 'cpc' &&
          userTestGroup !== 'a'
        ) {
          return;
        }
        if (!promptedForMailing || promptedForMailing !== '1') {
          if (now < JSON.parse(promptedForMailing)) {
            return;
          } else if (
            now > JSON.parse(promptedForMailing) ||
            (promptedForMailing && !open)
          ) {
            setOptInModalOpen(true);
          }
        }
      }, timer);
    }
    if (wideBanner !== '0' && website.wide_banners.banners.length) {
      setWideBanner('1');
    }
  }, [ageConfirm, wideBanner]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const ageconfirm = window.localStorage.getItem('confirmed21OrOlder');
      if (ageconfirm && ageconfirm.includes('1')) {
        toggleAgeVerification(false);
        clearInterval(intervalId);
      }
    }, 1);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const element = document.getElementById('store-information');
  useEffect(() => {
    // Scroll to the target element after navigation
    if (asPath.includes('#store-information') && element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [asPath, element]);

  // FUNCTIONS
  const getLayout = async () => {
    const { data } = await axios.get(`/api/layout?preview=${isPreview}`);
    if (data) {
      const mappedLayout = layoutMap(data);
      if (shopCategoryLinks.length) {
        mappedLayout.header.unshift({
          title: 'Shop',
          link: undefined,
          pixelLength: 41,
          sublinks: [
            ...shopCategoryLinks,
            { title: 'Shop All', url: shopLink }
          ].map((l) => ({
            title: l.title,
            link: l.url,
            sublinks: []
          }))
        });
      }
      setLayout(mappedLayout);
    }
  };

  const onConfirmAge = () => {
    setAgeConfirm('1');
    toggleAgeVerification(false);
  };

  const toggleOptInModal = () => {
    setOptInModalOpen(!optInModalOpen);
  };

  const toggleNav = (val: boolean) => {
    setNavOpen(val);
  };

  const updateMenuType = (menuType: PricingType) => {
    setPricingType(menuType);
    setShowPopup(false);
  };

  const updateOrderType = (type: OrderType) => {
    setOrderType(type);
  };

  const setShowPopup = (bool: boolean) => {
    if (!asPath.includes('/cart')) {
      togglePopup(bool);
      if (bool) {
        setTimeout(() => {
          togglePopup(false);
          setPopupProduct(null);
        }, 7000);
      }
    }
  };

  return (
    <AuthContext>
      <SnackbarProvider maxSnack={3}>
        <EcomContextProvider
          contextProps={{
            analytics,
            brand: process.env.BRAND,
            mobileView: !!(width && width <= 767),
            orderType,
            setOrderType: updateOrderType,
            pricingType,
            setPopupProduct,
            setShowPopup,
            showPopup
          }}>
          <CartContextProvider>
            <div
              className={
                ageVerificationOpen || navOpen ? styles.Modal : styles.Layout
              }>
              {ageVerificationOpen ? (
                <AgeVerification
                  {...ageVerificationMap(ageVerification, process.env.BRAND)}
                  submit={() => onConfirmAge()}
                />
              ) : null}
              {promptedForMailing === '0' ? (
                <MailingOptIn
                  form={website.opt_in_modal}
                  open={optInModalOpen}
                  toggle={toggleOptInModal}
                />
              ) : null}
              {wideBanner === '1' && website.wide_banners.banners.length ? (
                <WideBanner
                  {...wideBannerMap(website.wide_banners, process.env.BRAND)}
                  onClose={() => setWideBanner('0')}
                />
              ) : null}
              <DispensaryTitleBanner updateMenuType={updateMenuType} />
              <div className={styles['header-wrapper']}>
                <HeaderNavigation
                  brand={process.env.BRAND}
                  navLinks={layout.header}
                  navOpen={navOpen}
                  popupProduct={popupProduct}
                  socialLinks={layout.socialLinks}
                  toggleNav={toggleNav}
                  userLinks={layout.userLinks}
                />
              </div>
              <main>
                <div className={styles.content}>{children}</div>
                {asPath.includes('/shop') ? <MailingOptInBanner /> : null}
              </main>
              <LicenseBar
                text={csDispensary?.extra_footer_text || ''}
                brand={process.env.BRAND}
              />
              <FooterNavigation
                {...footerMap(website.footer, process.env.BRAND)}
                isKiosk={asPath.split('/')[1] === 'kiosk'}
                states={states}
              />
            </div>
          </CartContextProvider>
        </EcomContextProvider>
      </SnackbarProvider>
    </AuthContext>
  );
}

export default Layout;
